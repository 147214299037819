.about-slider{
	z-index:10;
	&.gbl{
		padding:0;
	}
	.inner{
		max-width:100%;
	}
	.slide{
		width:100%;
		height:100%;
		float:left;
	}
	&-panel{
		position:absolute;
		bottom:0;
		left:0;
		margin:$pad;
		padding:$pad;
		width:66.66%;
		width:calc(50% - #{$pad});
		min-width:480px;
		background:rgba($dark,0.75);
		color:$white;
		main{
			@include indented;
			@include edgeMargins;
		}
	}
	@include media($bp-s){
		.slide{
			height:auto;
		}
		figure.img-hero{
			position:relative;
			padding-top:66.66%;
			img{
				position:absolute;
				top:0;
				left:0;
			}
		}
		&-panel{
			position:relative;
			padding:$gutter;
			width:100%;
			margin:0;
			background:$white;
			color:$dark;
			main{
				margin-bottom:1.5*$pad;
			}
		}
	}
}