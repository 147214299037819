#nav-mobile{
	display:none;
	pointer-events:none;
	&.mm-menu{
			display: block;
	}
	&.mm-menu_opened{
		pointer-events:auto;
	}
	.mm-navbar{
		display:none;
	}
	.mm-panel{
		// padding:$pad;
		background:$dark;
		font-family:$titleFont;
		font-size:1.6rem;
		font-weight:normal;
		padding-top:$pad;
	}
	a.mm-listitem__text{
		padding:1em;
		color:$white;
	}
	li{
		width:100%;
		font-weight:bold;
	}
}

.mm-wrapper_opened{
	#header-gbl #nav-toggle{
		@extend .fa-times;
	}
	#window>*{
		overflow-x:hidden;
	}
	#header-gbl{
		overflow:hidden;
	}
	#cursor-e{
		opacity:0;
	}
}