.product-calculator{
	background:$white;
	&.gbl{
		padding:0;
	}
	.inner{
		position:static;
	}
	&-result{
		float:left;
		position:sticky;
		top:0;
		left:0;
		width:50%;
		height:100%;
		.img-bg{
			min-width:50vw;
			position:absolute;
			top:0;
			left:auto;
			right:0;
		}
	}
	.result-panel{
		@include middle;
		width:66.66%;
		max-width:420px;
		height:auto;
		background:$dark;
		padding:$pad;
		header{
			@extend h3;
			font-weight:bold;
			font-size:1.5rem;
			span{
				display:block;
				margin:0 0 0.3em;
			}
		}
		&-price{
			font-size:2rem;
			color:$gold;
		}
		main,
		footer{
			margin:$gutter 0 0;
		}
		.cta{
			@extend %cta;
			background:none;
		}
		.bill-items{
			li{
				width:100%;
				padding-right:2em;
				&+li{
					border-top:1px dotted $gold;
					padding-top:0.5em;
					margin-top:0.5em;
				}
				&.bill-item-add{
					@extend .fa-times;
					&::before{
						@extend .far;
						@extend %fabsolute;
						width:2em;
						left:auto;
						right:0;
						font-size:0.7em;
						top:1.8em;
						opacity:0.5;
					}
					&:hover{
						cursor:pointer;
						&::before{
							opacity:1;
						}
					}
				}
			}
			strong{
				float:right;
				margin-left:0.5em;
			}
		}
	}
	&-options{
		width:50%;
		float:right;
		padding:$pad;
		header{
			@include edgeMargins;
			@include indented;
			margin:$pad 0 $gutter;
			&:first-child{
				margin-top:0;
			}
		}
	}
	.dimension{
		background:rgba($gold,0.2);
		padding:1rem;
		&-detail{
			border:3px solid $dark;
			// background:$dark;
			width:auto;
			margin:0 0 1em;
			font-family:$titleFont;
			label,
			input{
				padding:1.3em 1.5em 1.1em;
				@extend %dfp;
				line-height:1;
			}
			label{
				background:$dark;
				color:$white;
				margin-bottom:-0.1em;
			}
			input{
				border:0;
				outline:0;
				width:90px;
				padding-right:2em;
				padding-left:1em;
				font-weight:bold;
				background:none;
				&:focus{
					background:white;
				}
			}
			&::after{
				content:"M";
				font-weight:bold;
				color:$dark;
				position:absolute;
				top:50%;
				right:0;
				text-align:left;
				width:2em;
				line-height:0;
				z-index:20;
				margin-top:0.1em;
			}
		}
		&-range{
			float:left;
			clear:left;
			// max-width:450px;
			border-left:3px solid $gold;
			border-right:3px solid $gold;
			*{
				cursor:e-resize;
			}
		}
		&+.dimension{
			margin-top:1rem;
		}
	}
	.additions{
		&-item{
			margin:0 0 1rem;
		}
	}
	.calc-add-on{
		@extend %cta;
		background:none;
		border:3px solid $gold;
		padding:1rem;
		width:100%;
		outline:0;
		font-size:0.8em;
		text-align:left;
		padding-right:2*$pad;
		@include border-radius(0);
		padding-left:calc(100px + 2rem + 3px);
		line-height:1.3;
		font-family:inherit;
		min-height:136px;
		min-height:calc(100px + 2rem + 6px);
		img{
			position:absolute;
			top:1rem;
			left:1rem;
			width:100px;
			height:100px;
			object-fit:cover;
		}
		h4,
		p{
			display:block;
		}
		h4{
			font-size:1rem;
			margin:0;
			margin-top:0.5rem;
			&+*{
				margin-top:0;
			}
		}
		.btn{
			@extend %cta;
			display:block;
			position:absolute;
			bottom:36px;
			right:36px;
			width:$pad;
			height:$pad;
			@include border-radius(50%);
			margin:0;
			@extend .fa-plus;
			color:$gold;
			&::before{
				@extend .far;
				@extend %fabsolute;
				color:inherit;
				font-size:1.2rem;
			}
		}
		&.active{
			// border-color:$dark;
			// @include box-shadow(none);
			.btn{
				color:$white;
				background:$gold;
				// border-color:$dark;
				@include box-shadow(none !important);
				@extend .fa-check;

			}
		}
		&:hover,
		&:focus{
			.btn{
				@include box-shadow(inset 0 0 0 1px $gold);
			}
		}
	}
	@include media($bp-m){
		&-result,
		&-options{
			width:100%;
			position:relative;
		}
		&-result{
			height:auto;
			padding:$pad;
			margin-top:$gutter;
			img.bg{
				min-width:100%;
			}
			.result-panel{
				@include no-middle;
				position:relative;
				margin-top:-2*$pad;
				width:100%;
				max-width:100%;
			}
		}
	}
	@include media($bp-s){
		&-result,
		&-options{
			padding:$gutter;
		}
		&-result{
			.result-panel{
				margin-top:-$pad;
				padding:$gutter;
			}
		}
		.calc-add-on{
			padding-right:1rem;
			.btn{
				position:relative;
				bottom:0;
				right:0;
				float:right;
				clear:both;
			}
		}
	}
	@include media($bp-xs){
		.calc-add-on{
			padding-left:1rem;
			.img{
				position:relative;
				top:0;
				left:0;
				margin:0 0 1rem;
			}
		}
	}
}