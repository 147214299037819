.blog-grid{
	&-main{
		@extend .col-row;
	}
	&-item{
		@extend %col;
		@extend .col-6;
		@extend .col-s-12;
	}
	figure.img{
		padding-top:75%;
		.img-bg{
			position:absolute;
			top:0;
			left:0;
		}
	}
	.blog-detail{
		width:100%;
		padding:$gutter;
		color:$dark;
		*{
			margin:0;
		}
		time{
			color:$white;
			margin:0 0 0.3em;
			display:block;
		}
		a.cta{
			margin:2em 0 0;
			border-color:$white;
			&:hover,
			&:focus{
				@include box-shadow(inset 0 0 0 1px white);
			}
		}
	}
	&-header{
		@include indented;
		max-width:600px;
		margin:0 0 $pad;
	}
	@include media($bp-m,min){
		&.gbl{
			padding-top:$pad;
		}
	}
}

section.banner-article{
	padding-top:0;
	padding-bottom:0;
}

figure.banner-article{
	width:100%;
	height:60%;
	min-height:50vh;
}

.blog-article{
	.inner{
		max-width:960px;
	}
	header{
		*{
			margin:0;
		}
	}
	main,
	footer{
		margin-top:$pad;
		@include indented;
	}
}