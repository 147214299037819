#header-gbl{
    height:$hh-d;
    padding-top:$gutter;
    padding-bottom:$gutter;
    z-index:10;
    background:$white;
    position:sticky;
    top:0;
    @include transition(all,$t);
    @include translateY(0);
    .scroll-down &{
        @include translateY(-100%);
    }
    a{
        @extend %noLink;
    }
    #header-logo{
        float:left;
        padding:0;
        height:$hh-d - $pad;
        img{
            width:auto;
            height:100%;
            opacity:1;
        }
        &::before{
            content:"";
            display:block;
            width:100%;
            min-width:100px;
            height:$hh-d - $pad;
            position:absolute;
            top:100%;
            left:0;   
            background-image:url(../images/athome-logo-m.svg);
            background-size:auto 100%;
            background-size:fit;
            background-position:0 0;
            background-repeat:no-repeat;
            opacity:0;
        }
        img,
        &::before{
            @include translateY(0);
            @include transition(all,$t);
        }
    }
    &.scrolled{
        #header-logo{
            img,
            &::before{
                @include translateY(-100%);
            }
            img{
                opacity:0;
            }
            &::before{
                opacity:1;
            }
        }
    }
    #header-nav{
        width:auto;
        min-width:50%;
        float:right;
    }
    #nav-toggle{
        display:none;
        float:right;
        @extend %cta;
        font-size:0;
        @extend .fa-bars;
        width:3.2rem;
        height:3.2rem;
        @include border-radius(50%);
        margin-left:1rem;
        &::before{
            @extend %fabsolute;
            @extend .fal;
            font-size:1rem;
        }
    }
    li{
        margin-right:2em;
        &:last-of-type{
            margin-right:0;
        }
    }
    a{
        display:block;
        text-decoration:none;
        color:inherit;
        font-family:$titleFont;
        @extend %letter-spacing;
        padding:1em 0;
    }
    .enquire{
        float:right;
        a{
            @extend %cta;
            margin:0;
            padding:1em 1.66em 0.8em;
        }
    }
    @include media($bp-m){
        #header-nav{
            min-width:0;
            top:-0.4rem;
            text-align:right;
            ul{
                width:auto;
            }
            ul,
            #nav-toggle{
                display:inline-block;
                float:none;
            }
            li{
                display:none;
                &.enquire{
                    display:block;
                }
            }
        }
        #nav-toggle{
            display:block;
        }
    }
    @include media($bp-s){
        #header-nav li.enquire{
            display:none;
        }
        #header-logo{
            max-width:calc(100% - 6rem);
            img{
                width:200px;
                height:auto;
                max-width:100%
            }
        }
    }
}