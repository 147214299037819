.list-faqs{
	border-top:1px solid $white;
	&-item{
		width:100%;
	}
	header{
		padding:$gutter 0;
		@include edgeMargins;
		border-bottom:1px solid $white;
		cursor:pointer;
		h3{
			padding-right:1em;
			@extend .fa-plus;
			&::before{
				@extend .far;
				font-size:0.8em;
				@extend %fabsolute;
				color:$white;
				width:2rem;
				right:0;
				left:auto;
			}
		}
		&.active h3:first-child{
			@extend .fa-minus;
		}
	}
	main{
		display:none;
		background:$white;
		main,
		figure.img,
		footer{
			@extend %dfp;
			width:50%;
		}
		main{
			padding:$pad;
		}
		figure.img{
			border:$gutter solid $white;
			padding-top:40%;
			img{
				@extend %dpCover;
			}
		}
		footer{
			padding:$gutter;
			a{
				float:right;
			}
		}
		@include media($bp-m){
			padding:$gutter;
			main,
			figure.img,
			footer{
				width:100%;
				border:0;
			}
			figure.img{
				padding-top:75%;
			}
			main,
			footer{
				margin-top:$gutter;
				padding:0;
			}
		}
	}
}