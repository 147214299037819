#gbl-404{
	width:100%;
	height:100%;
	height:calc(100% - #{$hh-d});
	background-image:url(../images/footer-bg-anim.gif);
	background-repeat:repeat;
	background-attachment:fixed;
	text-align:center;
	&~*{
		display:none;
	}
	h2{
		color:$gold;
	}
	.inner{
		max-width:420px;
		padding:$pad 0;
	}
}