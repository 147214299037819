.slider-prev,
.slider-next{
	display:block;
	width:$pad;
	height:$pad;
	@include border-radius(50%);
	border:3px solid $dark;
	background:none;
	outline:none;
	@include box-shadow(inset 0 0 0 0 $dark);
	@include transition(all,$t);
	&:hover,
	&:focus{
		@include box-shadow(inset 0 0 0 2px $dark);
	}
	&::before{
		@extend %fabsolute;
		@extend .fal;
	}
}
.slider-next{
	@extend .fa-arrow-right;
}
.slider-prev{
	@extend .fa-arrow-left;
}

.slider-basic{
	.slider-prev,
	.slider-next{
		position:absolute;
		bottom:$gutter;
		right:$gutter;
	}
	.slider-prev{
		right:$pad + $gutter + 10;
	}
}