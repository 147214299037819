.slider-product{
	&.gbl{
		padding:0;
	}
	.inner{
		max-width:100%;
	}
}

.slider-product-slider{
	li.slider-product-slide{
		width:100%;
	}
	figure.img{
		height:66.66vw;
		max-height:100vh;
	}
	main.product-info{
		position:absolute;
		bottom:0;
		left:0;
		width:50%;
		background:$white;
		@include translateY(100%);
		@include transition(all,.3s);
	}
	.is-selected main.product-info{
		@include translateY(0%);	
	}
	.product-detail{
		header,
		main{
			padding:$gutter;
		}
		header{
			padding-right:270px;
			*{
				margin:0;
			}
		}
		main{
			display:none;
			background:rgba($gold,0.2);
			*{
				max-width:600px;
			}
		}
	}
	.actions{
		width:auto;
		position:absolute;
		bottom:$gutter;
		right:$gutter;
		*:last-child{
			margin-right:0;
		}
	}
	.flickity-page-dots{
		position:absolute;
		bottom:0.85*$pad;
		right:180px;
		width:auto;
		.dot{
			width:1.3em;
			height:1.3em;
			border:3px solid $dark;
			opacity:1;
			background:none;
			&.is-selected{
				background:$dark;
			}
		}
	}
	.slider-prev,
	.slider-next{
		position:absolute;
		bottom:$gutter;
		right:$gutter;
	}
	.slider-prev{
		right:$pad + $gutter + 10;
	}
	@include media($bp-m){
		.flickity-page-dots{
			position:relative;
			clear:both;
			bottom:0;
			right:0;
			width:100%;
			padding:$gutter;
		}
		.slider-prev,
		.slider-next{
			display:none;
		}
		main.product-info{
			bottom:$gutter;
			@include translateY(0);
			width:calc(100% - #{$gutter});
		}
	}
	@include media($bp-s){
		main.product-info{
			position:relative;
			width:100%;
			header{
				padding-right::$gutter;
			}
			div.actions{
				position:relative;
				bottom:0;
				right:0;
				margin:1em 0 0;
				.expand{
					display:none;
				}
			}
			main{
				display:block !important;
			}
		}
		.flickity-page-dots{
			padding-top:0;
		}
	}
}