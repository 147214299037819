.enquire{
	&-intro,
	&-gbl{
		float:left;
	}
	&-gbl{
		padding:$gutter;
		margin-top:$pad;
		width:100vw;
		left:50%;
		@include translateX(-50%);
	}
	&-header{
		@include edgeMargins;
		@include indented;
		margin:0 0 $gutter;
		p{
			@extend .feat;
		}
	}
	&-main{
		max-width:600px;
		.form-field.product{
			display:none;
			input{
				background:$gold;
				color:$dark;
			}
		}
	}
	@include media($bp-s,min){
		&-gbl{
			padding:$pad;
		}
	}
	@include media($bp-m,min){
		&-intro,
		&-gbl{
			width:50%;
			position:sticky;
			top:$pad;
			padding-right:$pad;
		}
		&-gbl{
			float:right;
			margin-top:-2*$pad;
			padding-top:2*$pad + 14px;
			@include translateX(0);
			left:auto;
		}
		&-header,
		&-main{
			max-width:calc(600px - #{$pad});
		}
	}
	@include media(1366px,min){
		&-intro,
		&-gbl{
		}
		&-gbl{
			// 
			width:50vw;
			margin-right:calc(-0.5 * (100vw - 1200px));
		}
	}
}

form{
	&,
	label,
	input,
	textarea,
	fieldset,
	select{
		display:block;
		float:left;
		width:100%;
		padding:0;
		border:0;
	}
	input,
	textarea,
	select{
		border:2px solid $gold;
		background:none;
		color:inherit;
		padding:0.66rem;
		border-radius:0;
		outline:0;
		&:focus{
			@include box-shadow(inset 0 0 0 1px $gold);
		}
	}
	textarea{
		min-height:12rem;
		max-width:100%;
		resize: vertical;
	}
	option{
		color:$gold;
		background:$dark;
		border-bottom:2px solid $gold;
	}
	.form-field,
	.umbraco-forms-field {
		margin:0 0 1rem;
	}
	label{
		margin:0 0 0.5rem;
	}
	input[type=submit]{
		@extend %cta;
		background:$gold;
		color:$dark;
		width:auto;
		min-width:180px;
		border-color:transparent;
		&:hover,
		&:focus{
			background:$white;
			@include box-shadow(none);
		}
	}
	.checkboxlist{
		input,
		label{
			width:auto;
			display:inline-block;
			float:none;
		}
		input{
			margin:0 0.5em 0 0;
		}
		label{
			margin:0 0 0.5em;
		}
	}
}