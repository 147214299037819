#cursor-e{
	$size:$pad*1.5;
	position:fixed;
	z-index:20;
	pointer-events:none;
	mix-blend-mode: darken;
	@include rotate(-60deg);
	transform-origin:0 0;
	@include transition(transform,.3s);
	&.invert{
		mix-blend-mode: lighten;
	}
	&.alt{
		mix-blend-mode: multiply;
	}
	&.hover{
		@include rotate(0);
	@include transition(transform,2s);
		&::before{
			@include scale(1);
		}
	}
	&::before{
		content:"";
		display:block;
		width:$size;
		height:$size;
		margin:-0.5*$size;
		@include scale(0);
		@include transition(all,.15s);
		// opacity:0.3;
		background-image:url(../images/shape-tri.svg);
		background-size:fit;
		background-repeat:no-repeat;
	}
	&[data-ver=ver-1]::before{
		background-image:url(../images/shape-circ.svg);
	}
	&[data-ver=ver-2]::before{
		background-image:url(../images/shape-squ.svg);
	}
	// LIMIT TO CURSOR DEVICES
	display:none;
	@media (hover: hover) and (pointer: fine) {
	    display:block;
	}
}