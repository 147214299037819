*{
	position:relative;
	z-index:1;
	@include backface-hidden;
	@include box-sizing;
}

html,
body,
section,
header,
footer,
article,
aside,
main,
nav,
div{
	display:block;
	float:left;
	width:100%;
	height:auto;
	margin:0;
	padding:0;
}

html,
body{
	height:100%;
	width:100%;
	max-width:100%;
	overflow-x:hidden;
}

body{
	overflow-y:auto;
}

#window{
	width:100%;
	background:$white;
}

main#container{
	width:100vw;
}

.inner{
    max-width:1200px;
    float:none;
    margin:0 auto;
    @include selfClearing;
    @include edgeMargins;
}

header,
footer,
section{
	&.gbl{
		padding:2*$pad $pad;
		&+.gbl{
			margin-top:-$pad;
		}
		@include media($bp-s){
			padding:$gutter $gutter $pad;
			&+.gbl{
				margin-top:-$gutter;
			}
		}
		&[class*=bg-],
		[class*=bg-]+&{
			margin-top:0;
		}
	}
	&#header-gbl+.gbl{
		margin-top:0;
	}
}

main{
	@include edgeMargins;
}

img{
	max-width:100%
}

img.img-bg{
	width: 100%;
	height: 100%;
	object-fit:cover;
}

.bg-inv{
	background:$dark;
	color:$white;
}

.bg-gold{
	background:$gold;
}

.ah-tile{
	border:$gutter solid $white;
	border-left:0;
	*{
		z-index:0;
	}
	&::before,
	&::after{
		content:"";
		display:block;
		position:absolute;
		background:white;
		z-index:10;
	}
	&::before{
		width:$gutter;
		height:100%;
		top:0;
		left:50%;
		margin-left:-0.5*$gutter;
	}
	&::after{
		width:50%;
		height:$gutter;
		top:50%;
		left:0;
		margin-top:-0.5*$gutter;
	}
}

