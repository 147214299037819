.section-process-min{
	min-height:90vh;
	.process-main{
		width:50%;
		float:right;
		padding-left:2*$pad;
	}
	.process-main>h2{
		font-size:2em;
		left:-$pad;
	}
	.process-step{
		width:100%;
		main{
			display:none;
		}
		&+.process-step{
			margin-top:1rem;
		}
	}
	.process-step-header{
		float:left;
		width:100%;
		cursor:pointer;
		@extend h2;
		font-weight:bold;
		@extend .fa-plus;
		padding-right:1em;
		&::before{
			@extend .far;
			position:absolute;
			top:0.66em;
			right:0;
			font-size:0.5em;
		}
	}
	.active .process-step-header{
		@extend .fa-minus;
	}
	.active .process-step-header{
		color:$gold;
	}
	&.process-d{
		.inner{
			position:static;
		}
	}
	.process-figure{
		position:absolute;
		top:0;
		left:0;
		width:50%;
		height:100%;
		@extend .ah-tile;
		border-color:$dark;
		border-left:$gutter solid $dark;
		border-right:0;
		&::before,
		&::after{
			background:$dark;
		}
		&::after{
			left:50%;
		}
		.process-step-detail{
			position:absolute;
			top:0;
			right:0;
			width:50%;
			height:50%;
			padding:$gutter $gutter 1.5*$gutter 1.5*$gutter;
			font-size:0.9em;
		}
	}
	.process-step-detail{
		background:$gold;
		color:$dark;
		padding:$gutter;
		h3{
			font-size:1.5rem;
			margin:0 0 0.3em;
		}
	}
	@include media($bp-m,min){
		.process-step{
			main{
				display:none !important;
			}
			&-header{
				padding-right:0;
				&::before{
					display:none;
				}
			}
		}
	}
	@include media($bp-m){
		.process-figure{
			display:none;
		}
		.process-main{
			width:100%;
			padding-left:0;
			&>h2{
				left:0;
				opacity:0.5;
			}
		}
		.process-step{
			main{
				background:$gold;
				margin:$gutter 0;
			}
			figure.img{
				width:33.33%;
				width:calc(240px);
				height:100%;
				position:absolute;
				top:0;
				left:0;
			}
			&-detail{
				width:66.66%;
				width:calc(100% - 240px);
				float:right;
				min-height:240px;
				h3{
					display:none;
					&+*{
						margin-top:0;
					}
				}
			}
			&+.process-step{
				padding-top:1.3rem;
				border-top:1px solid $gold;
			}
		}
	}
	@include media($bp-s){
		.process-step{
			figure.img{
				width:40%;
			}
			&-detail{
				width:60%;
				min-height:20%;
			}
		}
	}
	@include media($bp-xs){
		.process-step{
			figure.img{
				position:relative;
				width:100%;
				height:90vw;
			}
			&-detail{
				width:100%;
				min-height:0;
			}
		}
	}
}

.process-expanded{
	&.gbl{
		padding-top:0;
	}
	&-section{
		&+section{
			margin-top:$pad;
		}
		h2{
			color:$gold;
		}
		figure.hero{
			margin:0 0 $pad;
			width:100vw;
			height:66.66vw;
			left:50%;
			@include translateX(-50%);
		}
		main{
			@include indented;
		}
		&.scroll-vis{
			opacity:1;
			@include transition(all,.3s);
		}
	}
	figure.process-figure{
		display:none;
		float:left;
		position:sticky;
		top:0;
		left:0;
		width:50%;
		height:100vh;
		figure{
			display:block;
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
		}
	}
	@include media($bp-m,min){
		&.gbl{
			padding:0;
		}
		.inner{
			max-width:100%;
		}
		figure.process-figure{
			display:block;
		}
		&-section{
			opacity:0.1;
		}
		&-container{
			width:50%;
			max-width:600px;
			float:left;
			padding:$pad 0 $pad $pad;
		}
		figure.hero{
			display:none;
		}
		&-section:nth-child(1n){
			margin:$pad 0;
			&:last-child{
				margin-bottom:3*$pad;
			}
		}
	}
}