#footer-gbl{
	padding-bottom:$pad;
	background-image:url(../images/footer-bg-anim.gif);
	background-repeat:repeat;
	background-attachment:fixed;
	&::before{
		content:"";
		background-color:inherit;
		@extend %dpCover;
		opacity:0.5;
	}
	a{
        @extend %noLink;
    }
	#footer-primary,
	#nav-footer{
		@extend %dfp;
		width:50%;
	}
	#footer-primary{
		max-width:420px;
		h4,
		.link{
			text-decoration:none;
			color:inherit;
			font-family:$titleFont;
			font-weight:normal;
			display:block;
			margin:0 0 1em;
			@extend %letter-spacing;
			font-size:$font-l;
		}
		.link{
			color:$gold;
			&:hover,
			&:focus{
				color:$white;
			}
		}
	}

	#footer-logo{
		display:block;
		width:210px;
		margin:0 0 $pad;
	}

	.newsletter{
		margin:1em 0 2em;
		&-container{
			width:100%;
			max-width:300px;

			//Remove Umbraco styling for display/padding/margin
			.umbraco-forms-label, .umbraco-forms-navigation {
				display: none;
			}

			.umbraco-forms-container {
				margin-bottom: 0;
			}

			.umbraco-forms-form fieldset, form, .umbraco-forms-field.email, .umbraco-forms-form.newsletter, .umbraco-forms-form input.text  {
				margin: 0 !important;
			}

			.umbraco-forms-form fieldset {
				padding: 0;
			}
			//Remove Umbraco styling for display/padding/margin

			.field-validation-error {
				color: #c22d1b !important;
			}
		}
		input{
			width:100%;
			background:none;
			padding:1em;
			border:1px solid $white;
			outline:none;
			color:$gold;
			padding-right:$pad;
			margin: 0;
			&:hover,
			&:focus{
				border-color:$gold;
				&+.submit{
					color:$gold;
				}
			}

		}
		.submit{
			display:block;
			position:absolute;
			top:0;
			right:0;
			width:$pad;
			height:100%;
			font-size:0;
			border:none;
			outline:none;
			background:none;
			@extend .fa-arrow-right;
			color:$white;
			&::before{
				@extend %fabsolute;
				@extend .fal;
				font-size:1rem;

			}
		}
	}
	#nav-footer{
		float:right;
		li{
			clear:left;
		}
		a{
			@extend h2;
			display:block;
			font-weight:bold;
			color:$white;
			text-decoration: none;
			margin:0 0 0.8em;
		}
	}

	#footer-badge{
		display:block;
		float:right;
		clear:both;
		width:300px;
		margin:3*$pad 0 0;
	}

	ul.social a{
		color:$dark;
		&:hover,
		&:focus{
			background:$white;
		}
	}

	.footer-sub{
		font-size:0.8em;
		letter-spacing:0.05em;
		position:absolute;
		bottom:0;
		left:0;
		a{
			margin-right:1em;
		}
	}

	@include media($bp-m){
		&.gbl{
			padding-bottom:$gutter;
		}
		#footer-primary,
		#nav-footer{
			width:100%;
			margin:0 0 $pad;
		}
		#footer-badge{
			max-width: 50%;
			margin:$pad 0 0;
		}
		.footer-sub{
			position:relative;
			// margin-top:$pad;
		}
	}
}