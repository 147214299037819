// GBL VARS
$gutter:30px;
$pad:60px;

$bp-m:1080px;
$bp-s:720px;
$bp-xs:450px;

$t:.15s;

// HEADER HEIGHT
$hh-d:100px;
$hh-m:100px;

$dark:#252235;
$gold:#d7b460;

$white:#FFF;
$black:$dark;

// LIBRARY
@import '_lib/_normalize';
@import '_lib/_mixins';
@import '_lib/_placeholders';
@import '_lib/_grid';

// == Font Awesome
@import 'font-awesome/scss/fontawesome'; // Main Family
@import 'font-awesome/scss/regular';  // Regular weight
@import 'font-awesome/scss/light'; // Light Weight
@import 'font-awesome/scss/solid'; // Solid Weight
@import 'font-awesome/scss/brands'; // Light Weight
@import 'font-awesome/scss/duotone'; // Light Weight

// == Vendor
@import 'vendor/_flickity.scss';
@import 'vendor/mmenu.scss';
// @import 'vendor/jquery.mmenu.scss';
// @import 'vendor/jquery.mmenu.positioning.scss';

@import '_gbl/_gblLayout';
@import '_gbl/_gblType';
@import '_gbl/_gblUX';

// GBL MODULES
@import '_modules/_header';
@import '_modules/_footer';
@import '_modules/_nav';
@import '_modules/slider';
@import '_modules/lib-range.scss';

// ELEMS 

@import '_elems/block-sections';
@import '_elems/process-sections';
@import '_elems/product-slider';
@import '_elems/product-grid';
@import '_elems/product-detail';
@import '_elems/product-calculator';
@import '_elems/about-slider';
@import '_elems/blog';
@import '_elems/faqs';
@import '_elems/enquire';
@import '_elems/404';





