.section-product-spec{
	&.gbl{
		padding-top:0;
		padding-bottom:0;
		@include media($bp-s){
			padding-top:$gutter;
		}
	}
	.inner{
		position:static;
	}
	figure.img-hero{
		position:sticky;
		top:0;
		width:50%;
		width:50vw;
		height:100vh;
		float:right;
		margin-right:calc(-50vw + 600px);
		img{
			width:100%;
			height:100%;
			object-fit:cover;
			float:left;
		}
		@include media(1340px){
			width:calc(50% + #{$pad});
			margin-right:-$pad;
		}
	}
	main{
		width:50%;
		padding:$pad;
		padding-left:0;
		@include indented;
	}
	@include media($bp-m){
		figure.img-hero{
			position:relative;
			width:100%;
			height:66vw;
			margin-right:0;
		}
		main{
			width:100%;
			padding-left:$pad;
			padding-right:0;
			margin:0;
		}
	}
	@include media($bp-xs){
		main{
			padding-left:0;
		}
	}
}

ul.product-features{
	// @extend .col-row;
	margin:1em 0 0;
	li.product-feature-item{
		@include edgeMargins;
		// @extend %col;
		// @extend .col-6;
		// @extend .col-s-12;
		font-size:0.9em;
		line-height:1.4;
		margin-bottom:$gutter;
		clear:left;
		max-width:600px;
		&:nth-child(2n+1){
		}
	}
	h4{
		margin:0 0 0.33em;
		&+p{
			margin:0;
		}
	}
}