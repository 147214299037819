$unit:100%/12;

[class*=col-],
%col{
	@extend %dfp;
	margin-right:$gutter;
	margin-bottom:$gutter;
	&.clear{
		clear:left;
	}
	@include media($bp-xs){
		margin-right:20px;
			margin-bottom:20px;
	}
}

.col-row{
	width: calc(100% + #{$gutter});
	outline:0;
	margin-bottom:0;
	margin-bottom:-$gutter;
	@include media($bp-xs){
		width: calc(100% + 20px);
		margin-bottom:-20px;
	}
}

ul.col-row{
	padding:0;
	margin-top:0;
	&,
	>li{
		list-style:none;
	}
}

@for $i from 1 through 12{
	.col-#{$i}{
		width:calc((#{$unit} * #{$i}) - #{$gutter});
	}
}

@include media($bp-m){
	@for $i from 1 through 12{
		.col-m-#{$i}{
			width:calc((#{$unit} * #{$i}) - #{$gutter});
		}
		.clear-m{
			clear:left;
		}
	}
}

@include media($bp-s){
	@for $i from 1 through 12{
		.col-s-#{$i}{
			width:calc((#{$unit} * #{$i}) - #{$gutter});
		}
		.clear-s{
			clear:left;
		}
	}
}

@include media($bp-xs){
	@for $i from 1 through 12{
		.col-#{$i},
		.col-m-#{$i},
		.col-s-#{$i}{
			width:calc((#{$unit} * #{$i}) - 20px);
		}
		.col-xs-#{$i}{
			width:calc((#{$unit} * #{$i}) - 20px);
		}
		.clear-xs{
			clear:left;
		}
	}
}