.section-intro{
	overflow:hidden;
	height:100%;
	height: calc(100% - #{$hh-d});
	.inner{
		position:static;
	}
	.img-hero{
		position:absolute;
		width:50%;
		height:100%;
		top:0;
		right:0;
	}
	main{
		width:50%;
		padding-right:$pad;
		@include indented;
	}
	&.product{
		.img-hero{
			right:auto;
			left:0;
		}
		main{
			float:right;
			padding-right:0;
			padding-left:2*$pad;
		}
	}
	@include media($bp-m){
		height:auto;
		&.gbl{
			padding-top:0;
		}
		.img-hero{
			position:relative;
			width:100%;
			height:66vw;
			border:0;
			margin:0 0 $pad;
		}
		main{
			width:100%;
		}
		&.product{
			main{
				padding-left:$pad;
			}
		}
	}
	@include media($bp-s){
		.img-hero{
			padding-top:100%;
			.img-bg{
				position:absolute;
				top:0;
				left:0;
			}
		}
		main{
			padding-right:0;
		}
	}
	@include media($bp-xs){
		&.product main{
			padding-left:0;
		}
	}
}

.section-info{
	// overflow:hidden;
	// .inner{
	// 	position:static;
	// }
	// .img-hero{
	// 	position:absolute;
	// 	width:50%;
	// 	height:100%;
	// 	top:0;
	// 	left:0;
	// 	.badge{
	// 		width:50%;
	// 		height:auto;
	// 		@include middle;
	// 	}
	// }
	// main{
	// 	width:50%;
	// 	padding-left:$pad;
	// 	float:right;
	// 	@include indented($pad);
	// }
	// @include media($bp-m){
	// 	height:auto;
	// 	&.gbl{
	// 		padding-top:$pad;
	// 	}
	// 	.img-hero{
	// 		position:relative;
	// 		width:100%;
	// 		height:66vw;
	// 		border:0;
	// 		margin:0 0 $pad;
	// 	}
	// 	main{
	// 		width:100%;
	// 		padding-left:$pad;
	// 	}
	// }
	// @include media($bp-s){
	// 	&.gbl{
	// 		padding-top:$gutter;
	// 	}
	// 	.img-hero{
	// 		padding-top:100%;
	// 		.img-bg{
	// 			position:absolute;
	// 			top:0;
	// 			left:0;
	// 		}
	// 	}
	// }
	// @include media($bp-xs){
	// 	main{
	// 		padding-left:0;
	// 	}
	// }
	.img-hero{
		margin-bottom:$pad;
		width:100vw;
		max-width:100vw;
		left:50%;
		@include translateX(-50%);
		padding-top:100%;
		.badge{
			width:50%;
			height:auto;
			@include middle;
		}
		.img-bg{
			position:absolute;
			top:0;
			left:0;
		}
	}
	&.gbl{
		padding-top:0;
	}
	@include media($bp-s,min){
		.img-hero{
			padding-top:75%
		}
	}
	@include media($bp-m,min){
		&.gbl{
			padding:0;
		}
		.inner{
			max-width:100%;
		}
		.img-hero{
			width:50%;
			margin:0;
			float:left;
			position:sticky;
			top:0;
			height:100vh;
			padding:0;
			left:0;
			@include translateX(0);
		}
		main{
			width:50%;
			max-width:600px;
			padding:$pad;
			padding-right:0;
		}
		&+.section-info:nth-child(even){
			.img-hero{
				float:right;
			}
		}
	}
}

figure.banner-inline{
	@extend %dfp;
	width:100%;
	padding:$pad;
	min-height:70%;
	&::before{
		content:"";
		@extend %dpCover;
		@include gradHor($white,transparent);
		z-index: 2;
		opacity:0.5;
	}
	img{
		position:absolute;
		@include middle;
	}
	main{
		width:100%;
		max-width:420px;
		z-index:10;
		@include indented;
	}
	@include media($bp-s){
		min-height:0;
		padding:0;
		&::before{
			display:none;
		}
		.img-bg{
			position:relative;
			@include no-middle;
			width:100%;
			height:66vw;
		}
		main{
			background:$white;
			outline:$pad solid $white;
		}
	}
	@include media($bp-xs){
		main{
			outline:none;
			margin:$gutter 0 0;
		}	
	}
}
