$bodyFont: europa, sans-serif;
$titleFont: itc-avant-garde-gothic-pro, sans-serif;

$font-l:1.2rem;

%letter-spacing{
	letter-spacing:0.04em;
}

%fabsolute{
	position:absolute;
	top:50%;
	left:0;
	width:100%;
	text-align:center;
	line-height:0;
}

@mixin indented($val:0){
	padding-left:$pad+$val;
	h1,
	h2,
	h3{
		left:-$pad;
	}
	@include media($bp-xs){
		padding-left:0;
		h1,
		h2,
		h3{
			left:0;
		}	
	}
}

body{
	color:$dark;
	font-size:16px;
	line-height:1.5em;
	font-family:$bodyFont;
}

h1,
h2,
h3,
h4,
h5{
	font-family:$titleFont;
}

h1{
	font-size:4rem;
	line-height:1.2;
	@include media($bp-s){
		font-size:4rem - 1rem;
	}
	small{
		display:block;
		font-weight:normal;
		font-size:1.5rem;
	}
}

h2{
	font-size:3rem;
	line-height:1.2;
	@include media($bp-s){
		font-size:3rem - 1rem;
	}
}

h3{
	font-size:2.3rem;
	line-height:1.2;
	@include media($bp-s){
		font-size:2.3rem - 1rem;
	}
}

h4{
	font-size:1.3rem;
}

a:not([class]),
%a{
	color:inherit;
	text-decoration:none;
	border-bottom:1px solid $gold;
}

%noLink{
	border-bottom:none;	
}

p.feat,
.feat{
	color:$gold;
}

a.cta,
%cta{
	display: inline-block;
	margin:1em 1em 0 0;
	padding:1.1em 1.66em 1em;
	background:none;
	font-family: $titleFont;
	text-decoration:none;
	color:inherit;
	line-height:1;
	border-radius:2em;
	border:3px solid $gold;
	@extend %letter-spacing;
	outline:none;
	vertical-align:top;
	@include box-shadow(inset 0 0 0 0 $gold);
	@include transition(all,$t);
	&:hover,
	&:focus{
		@include box-shadow(inset 0 0 0 0.1em $gold);
	}
}

a[href='#']{
	outline:none;
}

button.share{
	background:none;
	@extend %cta;
	@extend .fa-share-alt;
	&::before{
		@extend .far;
		margin-right:0.5em;
	}
}

.expand{
	@extend %cta;
	@extend .fa-plus;
	height:3.7em;
	top:-0.11em;
	&::before{
		@extend .fal;
		width:100%;
		position:absolute;
		top:50%;
		left:0;
		line-height:0;
		text-align:center;
		padding-left:0em;
		padding-right:0em;
	}
	&.active{
		@extend .fa-minus;
	}
}

ul[class]{
	@extend %listless;
}

ul.social{
	@extend %listless;
	li{
		margin-right:0.5rem !important;
		margin-bottom:0.5em;
		&:last-child{
			margin-right:0 !important;
		}
	}
	a{
		font-size:0;
		display:block;
		width:3.6rem;
		height:3.6rem;
		background:$gold;
		color:$dark;
		&::before{
			@extend .fab;
			font-size:1.3rem;
			position:absolute;
			top:50%;
			left:0;
			width:100%;
			line-height:0;
			text-align:center;
		}
		&:hover,
		&:focus{
			background-color:$gold;
		}
	}
	.twitter{
		@extend .fa-twitter;
	}
	.facebook{
		@extend .fa-facebook-f;
	}
	.linkedin{
		@extend .fa-linkedin-in;
	}
	.instagram{
		@extend .fa-instagram;
	}
	.youtube{
		@extend .fa-youtube;
	}
	.pinterest{
		@extend .fa-pinterest-p;
	}
}

.content-general{
	.inner{
		max-width:900px;
	}
}