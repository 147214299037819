.product-grid{
	&.gbl{
		padding-top:$pad;
		@include media($bp-s){
			padding-top:$gutter;
		}
	}
	&-main{
		@extend .col-row;
	}
	&-item{
		@extend %col;
		@extend .col-6;
		@extend .col-s-12;
	}
	figure.img{
		padding-top:100%;
		.img-bg{
			position:absolute;
			top:0;
			left:0;
		}
	}
	.product-detail{
		width:100%;
		padding:$gutter;
		background:$white;
		color:$dark;
		padding-right:190px;
		*{
			margin:0;
		}
	}
	.actions{
		position:absolute;
		width:auto;
		// float:right;
		margin:$gutter 0 0;
		bottom:$gutter;
		right:$gutter;
	}
	&-header{
		width:100%;
		margin:0 0 $gutter;
		cursor:pointer;
		header,
		main{
			padding:$gutter;
			@include edgeMargins;
			border:3px solid $gold;
			color:$gold;
		}
		header{
			padding-top:1.1*$gutter;
			@include transition(all,$t);
			h2{
				padding-right:1em;
				@extend .fa-plus;
				&::before{
					@extend .far;
					display:block;
					width:1em;
					text-align:center;
					font-size:0.8em;
					position:absolute;
					top:0.15em;
					right:0;
				}
			}
			&:hover{
				color:$white;
				border-color:$white;
			}
			&.active{
				background:$gold;
				color:$dark;
				border-color:$gold;
				h2{
					@extend .fa-minus;
				}
			}
		}
		main{
			display:none;
			border-top:0;
		}
	}
	@include media($bp-m){
		.product-detail{
			padding-right:$gutter;
		}
		.actions{
			position:relative;
			bottom:0;
			right:0;
		}
	}
}